import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const ContentContainer = styled.div`
  max-width: 1540px;
  padding: ${props => (props.nopadding ? '0' : '0 20px')};
  margin: 0 auto;
  ${breakpoint('lg')` 
    padding: ${props => (props.nopadding ? '0' : '0 30px')};
  `}
  ${breakpoint('lg')` 
    padding: ${props => (props.nopadding ? '0' : '0 40px')};
  `}
`;

const Content = props => {
  return (
    <ContentContainer nopadding={props.nopadding} {...props}>
      {props.children}
    </ContentContainer>
  );
};

export default Content;

import { Link } from '@3nvi/gatsby-theme-intl';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';

const CookiePopupWrapper = styled.div`
  max-width: 500px;
  position: fixed;
  right: 0;
  bottom: 0;
  .CookieConsent {
    max-width: 700px;
    position: static !important;
    display: flex;
    align-items: center !important;
  }
`;

const CookiePopup = () => {
  return (
    <CookiePopupWrapper>
      <CookieConsent
        location="bottom"
        buttonText="Akceptuj"
        cookieName="studioFNCCookie"
        style={{ background: 'black', color: 'white', fontSize: '16px' }}
        buttonStyle={{ color: 'white', fontSize: '18px', backgroundColor: '#1111F5' }}
        expires={150}
      >
        Ta strona używa ciasteczek. Korzystając ze strony wyrażasz zgodę na używanie cookies,
        zgodnie z ustawieniami przeglądarki.{' '}
        <Link to="/polityka" style={{ color: 'white' }}>
          Dowiedz się więcej
        </Link>
      </CookieConsent>
    </CookiePopupWrapper>
  );
};

export default CookiePopup;

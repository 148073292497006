import styled from 'styled-components';
import ArrowBlackLeft from '../images/arrow_black_left.svg';
import ArrowWhiteLeft from '../images/arrow_white_left.svg';
import ArrowWhiteRight from '../images/arrow_white_right.svg';
import ArrowBlackRight from '../images/arrow_black_right.svg';
import { Link } from '@3nvi/gatsby-theme-intl';

export const ButtonTemplate = styled.button`
  letter-spacing: 2.5px;
  font-size: 18px;
  color: ${props => props.textColor};
  text-transform: uppercase;
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: auto 15px;
  background-position: center left;
  background-color: transparent;
  border: none;
  transition: background-position 0.3s;
  font-weight: bold;
  cursor: pointer;
`;

export const ArrowButtonLeft = styled(ButtonTemplate)`
  padding-left: 30px;
  background-image: url(${props =>
    props.textColor === 'black' ? ArrowBlackLeft : ArrowWhiteLeft});
  background-position: center left 5px;
  &:hover,
  &:focus {
    background-position: center left;
  }
`;

export const ArrowButtonRight = styled(ButtonTemplate)`
  padding-right: 30px;
  background-image: url(${props =>
    props.textColor === 'black' ? ArrowBlackRight : ArrowWhiteRight});
  background-position: center right 5px;
  &:hover,
  &:focus {
    background-position: center right;
  }
`;

export const LinkTemplate = styled.a`
  letter-spacing: 2.5px;
  font-size: 18px;
  color: ${props => props.textColor};
  text-transform: uppercase;
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: auto 15px;
  transition: background-position 0.3s;
  font-weight: bold;
  cursor: pointer;
`;

export const ArrowLinkLeft = styled(LinkTemplate)`
  padding-left: 30px;
  background-image: url(${props =>
    props.textColor === 'black' ? ArrowBlackLeft : ArrowWhiteLeft});
  background-position: center left 5px;
  &:hover,
  &:focus {
    background-position: center left;
  }
`;

export const ArrowLinkRight = styled(LinkTemplate)`
  padding-right: 30px;
  background-image: url(${props =>
    props.textColor === 'black' ? ArrowBlackRight : ArrowWhiteRight});
  background-position: center right 5px;
  &:hover,
  &:focus {
    background-position: center right;
  }
`;

export const InternalLinkTemplate = styled(Link)`
  letter-spacing: 2.5px;
  font-size: 18px;
  color: ${props => props.textColor};
  text-transform: uppercase;
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: auto 15px;
  transition: background-position 0.3s;
  font-weight: bold;
  cursor: pointer;
`;

export const ArrowInternalLinkLeft = styled(InternalLinkTemplate)`
  padding-left: 30px;
  background-image: url(${props =>
    props.textColor === 'black' ? ArrowBlackLeft : ArrowWhiteLeft});
  background-position: center left 5px;
  &:hover,
  &:focus {
    background-position: center left;
  }
`;

export const ArrowInternalLinkRight = styled(InternalLinkTemplate)`
  padding-right: 30px;
  background-image: url(${props =>
    props.textColor === 'black' ? ArrowBlackRight : ArrowWhiteRight});
  background-position: center right 5px;
  &:hover,
  &:focus {
    background-position: center right;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { useTranslation, Link } from '@3nvi/gatsby-theme-intl';

import ContentBlock from './contentBlock';

import FacebookIcon from '../images/facebook.svg';
import BehanceIcon from '../images/behance.svg';
import MessengerIcon from '../images/messenger.svg';
import InstagramIcon from '../images/instagram.svg';
import breakpoint from 'styled-components-breakpoint';

const FooterContainer = styled.div`
  h2.block-title {
    color: white;
    margin-bottom: 30px;
  }
  color: white;
  font-size: 18px;
  .block-wrapper {
    padding: 70px 0;
  }
  a {
    color: white;
    text-decoration: none;
  }
  .footer-bottom-wrapper {
    div:last-child {
      margin-top: 30px;
    }
    ${breakpoint('lg')`
      display: flex;
      justify-content: space-between;
      div:last-child {
        margin-top: 0;
      }
    `}
  }
`;

const Address = styled.p``;

const ContactInfo = styled.div`
  margin: 50px 0;
  span {
    background: #1111f5;
    display: inline-block;
    width: 340px;
    padding: 15px 20px;
    font-size: 22px;
    font-weight: bold;
    margin-left: -20px;
  }
`;

const AdditionalInfo = styled.div`
  a {
    text-decoration: underline;
  }
`;
const SocialLinks = styled.div`
  img {
    height: 43px;
  }
  a:not(:last-child) {
    margin-right: 22px;
  }
`;

const SocialLink = ({ url, image, alt = ' ' }) => (
  <a href={url} target="_blank" rel="noreferrer">
    <img src={image} alt={alt} />
  </a>
);

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <ContentBlock id="contact" title={t('menu.contact')} background="black">
        <Address className="address">
          studio FNC
          <br />
          plac Jana Matejki 12, 31-157 Kraków
          <br />
          {/* ({t('footer.lab')})<br /> */}
        </Address>
        <ContactInfo>
          <span>
            tel. – <a href="tel:48509132640">+ 48 509 132 640</a>
            <br />
            e-mail – <a href="mailto:kontakt@studiofnc.pl">kontakt@studiofnc.pl</a>
          </span>
        </ContactInfo>
        <div className="footer-bottom-wrapper">
          <AdditionalInfo>
            <span
              dangerouslySetInnerHTML={{
                __html: t('footer.policy'),
              }}
            />
            <br />
            <span>
              <Link to="/eko">{t('footer.eco')}</Link>
            </span>
          </AdditionalInfo>
          <SocialLinks>
            <SocialLink
              image={FacebookIcon}
              alt="Odnośnik do facebooka"
              url="https://www.facebook.com/studiofncpl"
            />
            <SocialLink
              image={MessengerIcon}
              alt="Odnośnik do messengera"
              url="https://www.facebook.com/messages/t/studiofncpl"
            />
            <SocialLink
              image={InstagramIcon}
              alt="Odnośnik do Instagrama"
              url="https://www.instagram.com/studio_fnc/"
            />
            <SocialLink
              image={BehanceIcon}
              alt="Odnośnik do profilu na Behance"
              url="https://www.behance.net/studioFNCpl"
            />
          </SocialLinks>
        </div>
      </ContentBlock>
    </FooterContainer>
  );
};

export default Footer;

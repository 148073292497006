import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Content from './content';

export const BlockWrapper = styled.div`
  padding: 40px 0;
  position: relative;
  h2.block-title {
    text-transform: lowercase;
    font-weight: 700;
    font-size: 40px;
    color: ${props => props.theme.primary};
  }
  p.block-description {
    max-width: 450px;
    font-size: 18px;
    margin-bottom: 40px;
  }
  padding-bottom: 80px;
  ${breakpoint('lg')`
    padding-top: 110px;
    padding-bottom: 110px;
  `}
  ${breakpoint('xl')`
    padding-bottom: 150px;
  `}
`;
const Block = styled.div``;

const ContentBlock = ({ title, description, children, background, ...props }) => {
  return (
    <BlockWrapper className="block-wrapper" style={{ background }} {...props}>
      <Content>
        <Block className="block">
          <h2 className="block-title">{title}</h2>
          {description ? <p className="block-description">{description}</p> : null}
          <div>{children}</div>
        </Block>
      </Content>
    </BlockWrapper>
  );
};

export default ContentBlock;
